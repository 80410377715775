export const USER_LOADED = 'USER_LOADED'
export const LOGOUT = 'LOGOUT'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const SET_ALERT = 'SET_ALERT'
export const REMOVE_ALERT = 'REMOVE_ALERT'
export const GET_USER = 'GET_USER'
export const GET_USERS_LIST = 'GET_USERS_LIST'
export const USER_CREATED_SUCCESS = 'USER_CREATED_SUCCESS'
export const USER_DELETED_SUCCESS = 'USER_DELETED_SUCCESS'
export const LOADING = 'LOADING'
export const AT_SET_NOTIFICATION = 'SET_NOTIFICATION'
export const AT_SET_INSURERS = 'AT_SET_INSURERS'
export const AT_SET_SUPPLIERS = 'AT_SET_SUPPLIERS'
export const AT_SET_VACCINE_ORGANIZATION_USERS =
  'AT_SET_VACCINE_ORGANIZATION_USERS'
export const AT_SET_ORGANIZATION_PARTNERTS = 'AT_SET_ORGANIZATION_PARTNERTS'
export const AT_SET_CAMPAIGNS = 'AT_SET_CAMPAIGNS'
export const FILE_UPLOADED_SUCCESSFULLY = 'FILE_UPLOADED_SUCCESSFULLY'
export const ORGANIZATION_CREATED_SUCCESS = 'ORGANIZATION_CREATED_SUCCESS'
export const AT_SET_ORGANIZATION_PARTNERTS_LIST = 'AT_SET_ORGANIZATION_PARTNERTS_LIST'
export const AT_SET_ORGANIZATION_PARTNER = 'AT_SET_ORGANIZATION_PARTNER'
export const AT_SET_ADDRESS_LIST = 'AT_SET_ADDRESS_LIST'
export const AT_SET_ADDRESS_MODAL = 'AT_SET_ADDRESS_MODAL'
export const AT_SET_AVAILABLE_OPTIONS = 'AT_SET_AVAILABLE_OPTIONS'
export const AT_SET_NEW_ADDRESS = 'AT_SET_NEW_ADDRESS'
export const AT_SET_REGION = 'AT_SET_REGION'
