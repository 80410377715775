import React, { Fragment, useState, useEffect } from 'react'
import centralizedLogo from 'assets/reach52_logo_violet.png'
import 'styles/login.style.scss'
import 'styles/ResetPassword.style.scss'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  emailResetPasswordLink,
  tokenExpiredNotification,
  resetUserPassword,
} from 'actions/user.action'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import IconButton from '@mui/material/IconButton'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import { TextField, Button } from '@mui/material'
import { Notification } from '../util/Notification'
import { useMatch, useNavigate, useParams, Link } from 'react-router-dom'

import {
  checkErrors,
  countFormData,
} from 'components/util/validation/form.validation'

const ResetPassword = ({
  notification,
  tokenExpiredNotification,
  emailResetPasswordLink,
  resetUserPassword,
}) => {
  const resetPassword = useMatch('/forget-password')
  const { token } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (token) {
      let tokenPayload = token.slice(token.indexOf('.') + 1)
      tokenPayload = JSON.parse(
        Buffer.from(
          tokenPayload.slice(0, tokenPayload.indexOf('.')),
          'base64'
        ).toString('binary')
      )

      if (!(tokenPayload.exp > new Date().getTime() / 1000)) {
        tokenExpiredNotification(navigate)
      }
    }
  }, [token, tokenExpiredNotification, navigate])

  const initialState = {
    email: '',
    password: '',
    confirmPassword: '',
  }
  const fieldErrors = {
    email: '',
    password: '',
    confirmPassword: '',
    empty: false,
  }

  const [passwordVisible, setPasswordVisible] = useState({
    password: false,
  })
  const [formData, setFormData] = useState(initialState)
  const [userErrorField, setUserErrorField] = useState(fieldErrors)
  const { email, password, confirmPassword } = formData

  const onChange = (e) => {
    checkErrors(e.target, formData, userErrorField, setUserErrorField)
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const onSubmit = (e) => {
    e.preventDefault()

    if (!resetPassword && token) {
      if (!(countFormData(formData) === Object.keys(formData).length - 1)) {
        setUserErrorField({
          ...userErrorField,
          empty: true,
        })
        setTimeout(() => {
          setUserErrorField({
            ...userErrorField,
            empty: false,
          })
        }, 2000)
      }

      if (
        countFormData(formData) === Object.keys(formData).length - 1 &&
        countFormData(userErrorField) === 0
      ) {
        let tokenData = token.slice(token.indexOf('.') + 1)
        tokenData = JSON.parse(
          Buffer.from(
            tokenData.slice(0, tokenData.indexOf('.')),
            'base64'
          ).toString('binary')
        )

        const requestBody = {}
        requestBody.userId = tokenData.id
        requestBody.token = token
        requestBody.password = formData.password
        resetUserPassword(requestBody, navigate)
      }
    } else {
      if (countFormData(userErrorField) === 0 && formData.email.length !== 0) {
        emailResetPasswordLink(email)
      } else {
        setUserErrorField({
          ...userErrorField,
          empty: true,
        })
        setTimeout(() => {
          setUserErrorField({
            ...userErrorField,
            empty: false,
          })
        }, 2000)
      }
    }
  }

  return (
    <Fragment>
      {notification.show ? (
        <div className='notification-container'>
          <Notification notification={notification} />
        </div>
      ) : (
        ''
      )}
      <div className='login-container'>
        <div className='login-form'>
          <div className='login-logo'>
            <h4 className='login-header'>
              <img src={centralizedLogo} alt='logo' />
              <p className='text-voilet'>Centralized Authentication</p>
            </h4>
          </div>
          <form className='password-reset-form' onSubmit={onSubmit}>
            {resetPassword ? (
              <div className='form-field'>
                <TextField
                  id='email'
                  label='Enter Email Id'
                  variant='outlined'
                  className='custom-form-input'
                  value={email}
                  name='email'
                  onChange={onChange}
                />
                {userErrorField.email && (
                  <label className='validation-error'>
                    {userErrorField.email}
                  </label>
                )}

                <span className='form-field'>
                  {userErrorField.empty && (
                    <label className='validation-error'>
                      Email Id is required
                    </label>
                  )}
                </span>
              </div>
            ) : (
              <div className='form-field'>
                <label className='form-label'>Set New Password</label>
                <FormControl sx={{ m: 1, width: '25ch' }}>
                  <OutlinedInput
                    type={passwordVisible.password ? 'text' : 'password'}
                    value={password}
                    name='password'
                    onChange={onChange}
                    onPaste={(e) => {
                      e.preventDefault()
                      return false
                    }}
                    className='input-outline-none'
                    placeholder='Enter new password'
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={() =>
                            setPasswordVisible({
                              ...passwordVisible,
                              password: !passwordVisible.password,
                            })
                          }
                          edge='end'
                        >
                          {passwordVisible.password ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>

                {userErrorField.password && (
                  <label className='validation-error'>
                    {userErrorField.password}
                  </label>
                )}

                <div className='form-field'>
                  <label className='form-label'>Confirm New Password</label>
                  <FormControl sx={{ m: 1, width: '25ch' }}>
                    <OutlinedInput
                      type={
                        passwordVisible.confirmPassword ? 'text' : 'password'
                      }
                      value={confirmPassword}
                      name='confirmPassword'
                      onChange={onChange}
                      onPaste={(e) => {
                        e.preventDefault()
                        return false
                      }}
                      className='input-outline-none'
                      placeholder='Confirm new password'
                    />
                  </FormControl>

                  {userErrorField.confirmPassword && (
                    <label className='validation-error'>
                      {userErrorField.confirmPassword}
                    </label>
                  )}
                </div>
                <span className='form-field'>
                  {userErrorField.empty && (
                    <label className='validation-error'>
                      Please fill up all the fields
                    </label>
                  )}
                </span>
              </div>
            )}
            <div>
              <Button
                className='custom-form-btn'
                variant='contained'
                size='large'
                fullWidth
                onClick={onSubmit}
                type='submit'
              >
                {resetPassword ? 'Send Recovery Link' : 'Update'}
              </Button>
            </div>
            <div className='separator'>
              <Link className='custom-form-link' to='/'>
                <span> Return to login </span>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  )
}

ResetPassword.propTypes = {
  emailResetPasswordLink: PropTypes.func.isRequired,
  tokenExpiredNotification: PropTypes.func.isRequired,
  resetUserPassword: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  notification: state.notification.notification,
})

export default connect(mapStateToProps, {
  tokenExpiredNotification,
  emailResetPasswordLink,
  resetUserPassword,
})(ResetPassword)
