import setAuthToken from 'lib/setAuthToken.lib'
import api from '../lib/api.lib'
import {
  GET_USERS_LIST,
  GET_USER,
  USER_CREATED_SUCCESS,
  AT_SET_NOTIFICATION,
  LOADING,
  AT_SET_VACCINE_ORGANIZATION_USERS,
  USER_LOADED,
} from './types.actions'
import { uploadProfilePicture } from './fileUpload.action'

export const loadUsers =
  ({timeout = 2000, roles = [], countries = [], searchKey = '', getOrganizationLinkedUsers = false, userId = '', getConnectUsers = false}) =>
  async (dispatch) => {
    const userRoles = roles.join(',')
    const userCountries = countries.join(',')
    try {
      const res = await api.get(`/user/`, {
        params: { userRoles, userCountries, searchKey, getOrganizationLinkedUsers, userId, getConnectUsers },
      })
      dispatch({
        type: GET_USERS_LIST,
        payload: res.data.data,
      })
      dispatch({
        type: AT_SET_NOTIFICATION,
        payload: {
          is_open: true,
          className: 'SUCCESS',
          message: res.data.message,
        },
      })
    } catch (error) {
      console.log(error)
      setTimeout(() =>
        dispatch({
          type: AT_SET_NOTIFICATION,
          payload: {
            is_open: true,
            className: 'FAILED',
            message: error.response.data.message
              ? 'Error: ' + error.response.data.message
              : 'Failed to get data',
          },
        })
      )
    }
    setTimeout(
      () =>
        dispatch({
          type: AT_SET_NOTIFICATION,
          payload: {
            is_open: false,
            className: '',
            message: '',
          },
        }),
      timeout
    )
  }
export const loadVaccineOrganizationUsers =
  (userId = '') =>
  async (dispatch) => {
    try {
      const res = await api.get(`/user/`, {
        params: { userId, getOrganizationUsers: true },
      })

      dispatch({
        type: AT_SET_VACCINE_ORGANIZATION_USERS,
        payload: res.data.data,
      })
    } catch (error) {
      console.log(error)
    }
  }

export const getUser =
  (userId, timeout = 2000) =>
  async (dispatch) => {
    try {
      const res = await api.get(`/user/${userId}`)

      dispatch({
        type: LOADING,
        payload: true,
      })
      if (res) {
        dispatch({
          type: GET_USER,
          payload: res.data.data,
        })
        dispatch({
          type: LOADING,
          payload: false,
        })
        dispatch({
          type: AT_SET_NOTIFICATION,
          payload: {
            is_open: true,
            className: 'SUCCESS',
            message: res.data.message,
          },
        })
      }
    } catch (error) {
      dispatch({
        type: AT_SET_NOTIFICATION,
        payload: {
          is_open: true,
          className: 'FAILED',
          message: error.response.data.message
            ? 'Error: ' + error.response.data.message
            : 'Failed to get data',
        },
      })
      console.log(error)
    }
    setTimeout(
      () =>
        dispatch({
          type: AT_SET_NOTIFICATION,
          payload: {
            is_open: false,
            className: '',
            message: '',
          },
        }),
      timeout
    )
  }

export const updateUser =
  (submittedData, userId, navigate, path, timeout = 2000) =>
  async (dispatch, getState) => {
    try {
      if (typeof submittedData.profilePic === 'object'){
        await dispatch(uploadProfilePicture(submittedData.profilePic, submittedData.country.iso3))
        const imageName = getState().user.profilePicture
        submittedData.profilePic = imageName
      }
      const res = await api.put(`/user/${userId}`, submittedData)
      dispatch({
        type: LOADING,
        payload: true,
      })
      if (res) {
        if (JSON.parse(localStorage.getItem('user')).id === userId) {
          let data = submittedData
          data.firstName = submittedData.firstname
          data.lastName = submittedData.lastname
          data.id = userId

          delete data.firstname
          delete data.lastname
          delete data.insurer
          delete data.isInsurerRoleAdmin
          delete data.isSupplierRoleAdmin
          delete data.supplier

          setAuthToken(localStorage.token, data)
          dispatch({
            type: USER_LOADED,
            payload: data,
          })
        }
        dispatch({
          type: LOADING,
          payload: false,
        })
        dispatch({
          type: AT_SET_NOTIFICATION,
          payload: {
            is_open: true,
            className: 'SUCCESS',
            message: res.data.message,
          },
        })
        navigate(path)
      }
    } catch (error) {
      dispatch({
        type: AT_SET_NOTIFICATION,
        payload: {
          is_open: true,
          className: 'FAILED',
          message: error.response.data.message
            ? Array.isArray(error.response.data.message)
              ? 'Error: ' +
                error.response.data.message.map((val) => val.message)
              : error.response.data.message
            : 'Failed to get data',
        },
      })
    }
    setTimeout(
      () =>
        dispatch({
          type: AT_SET_NOTIFICATION,
          payload: {
            is_open: false,
            className: '',
            message: '',
          },
        }),
      timeout
    )
  }
export const updatePassword =
  (submittedData, userId, navigate, path = '/users', timeout = 2000) =>
  async (dispatch) => {
    try {
      submittedData['userId'] = userId
      const res = await api.post(`/user/password/`, submittedData)
      dispatch({
        type: LOADING,
        payload: true,
      })
      if (res) {
        dispatch({
          type: LOADING,
          payload: false,
        })
        dispatch({
          type: AT_SET_NOTIFICATION,
          payload: {
            is_open: true,
            className: 'SUCCESS',
            message: res.data.message,
          },
        })
        navigate(path)
      }
    } catch (error) {
      dispatch({
        type: AT_SET_NOTIFICATION,
        payload: {
          is_open: true,
          className: 'FAILED',
          message: error.response.data.message
            ? Array.isArray(error.response.data.message)
              ? 'Error: ' +
                error.response.data.message.map((val) => val.message)
              : error.response.data.message
            : 'Failed to update data',
        },
      })
    }
    setTimeout(
      () =>
        dispatch({
          type: AT_SET_NOTIFICATION,
          payload: {
            is_open: false,
            className: '',
            message: '',
          },
        }),
      timeout
    )
  }

export const createUser =
  (requestBody, navigate, isConnectUser, timeout = 2000) =>
  async (dispatch, getState) => {
    try {
      await dispatch(uploadProfilePicture(requestBody.profilePic, requestBody.country.iso3))
      const imageName = getState().user.profilePicture
      requestBody.profilePic = imageName
      const res = await api.post(`/user/`, requestBody)

      dispatch({
        type: LOADING,
        payload: true,
      })
      if (res) {
        dispatch({
          type: USER_CREATED_SUCCESS,
          payload: res.data.data,
        })
        dispatch({
          type: AT_SET_NOTIFICATION,
          payload: {
            is_open: true,
            className: 'SUCCESS',
            message: res.data.message,
          },
        })
        dispatch({
          type: LOADING,
          payload: false,
        })
        if (isConnectUser) {
          navigate('/connect-users')
        } else{
          navigate('/users')
        }
      }
    } catch (error) {
      console.log(error)
      dispatch({
        type: AT_SET_NOTIFICATION,
        payload: {
          is_open: true,
          className: 'FAILED',
          message: error.response.data.message
            ? 'Error: ' + error.response.data.message
            : 'Failed to get data',
        },
      })
    }
    setTimeout(
      () =>
        dispatch({
          type: AT_SET_NOTIFICATION,
          payload: {
            is_open: false,
            className: '',
            message: '',
          },
        }),
      timeout
    )
  }

export const deleteUser =
  (userId, timeout = 2000) =>
  async (dispatch) => {
    try {
      const res = await api.delete(`/user/${userId}`)
      dispatch({
        type: AT_SET_NOTIFICATION,
        payload: {
          is_open: true,
          className: 'SUCCESS',
          message: res.data.message,
        },
      })
    } catch (error) {
      dispatch({
        type: AT_SET_NOTIFICATION,
        payload: {
          is_open: true,
          className: 'FAILED',
          message: error.response.data.message
            ? 'Error: ' + error.response.data.message
            : 'Failed to get data',
        },
      })
    }
    setTimeout(
      () =>
        dispatch({
          type: AT_SET_NOTIFICATION,
          payload: {
            is_open: false,
            className: '',
            message: '',
          },
        }),
      timeout
    )
  }

export const emailResetPasswordLink =
  (emailId, timeout = 3000) =>
  async (dispatch) => {
    try {
      const res = await api.get(`/user/reset-password/${emailId}`)
      dispatch({
        type: AT_SET_NOTIFICATION,
        payload: {
          is_open: true,
          className: 'SUCCESS',
          message: res.data.message,
        },
      })
    } catch (error) {
      console.log(error)
      dispatch({
        type: AT_SET_NOTIFICATION,
        payload: {
          is_open: true,
          className: 'FAILED',
          message: error.response.data.message
            ? 'Error: ' + error.response.data.message
            : 'Failed to get data',
        },
      })
    }
    setTimeout(
      () =>
        dispatch({
          type: AT_SET_NOTIFICATION,
          payload: {
            is_open: false,
            className: '',
            message: '',
          },
        }),
      timeout
    )
  }

export const tokenExpiredNotification =
  (navigate, timeout = 3000) =>
  async (dispatch) => {
    try {
      dispatch({
        type: AT_SET_NOTIFICATION,
        payload: {
          is_open: true,
          className: 'FAILED',
          message: 'Password reset link is expired !',
        },
      })
    } catch (error) {}
    setTimeout(
      () =>
        dispatch({
          type: AT_SET_NOTIFICATION,
          payload: {
            is_open: false,
            className: '',
            message: '',
          },
        }),

      timeout
    )
    setTimeout(() => navigate('/forget-password'), timeout)
  }

export const resetUserPassword =
  (requestBody, navigate, timeout = 2000) =>
  async (dispatch) => {
    try {
      const res = await api.post(`/user/reset-password`, requestBody)
      dispatch({
        type: AT_SET_NOTIFICATION,
        payload: {
          is_open: true,
          className: 'SUCCESS',
          message: res.data.message,
        },
      })
    } catch (error) {
      console.log(error)
      dispatch({
        type: AT_SET_NOTIFICATION,
        payload: {
          is_open: true,
          className: 'FAILED',
          message: error.response.data.message
            ? 'Error: ' + error.response.data.message
            : 'Failed to get data',
        },
      })
    }
    setTimeout(
      () =>
        dispatch({
          type: AT_SET_NOTIFICATION,
          payload: {
            is_open: false,
            className: '',
            message: '',
          },
        }),
      timeout
    )
    setTimeout(() => navigate('/'), timeout)
  }
